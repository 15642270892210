import { FixedNumber } from '@ethersproject/bignumber'

export const Zero = FixedNumber.from(0)

export const aprs = [
  {
    text: '3 Months',
    value: '7.2',
    icon: 'vault--green'
  },
  {
    text: '6 Months',
    value: '14.3',
    icon: 'vault--blue'
  },
  {
    text: '9 Months',
    value: '21.5',
    icon: 'vault--violet'
  }
]
