// import { walletStore } from "@/stores/wallet-store"
// import { when } from "mobx"
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/vault'
  },
  {
    path: '/vault',
    component: {
      render(c) {
        return c('router-view')
      }
    },
    children: [
      {
        path: '/',
        redirect: 'connect-wallet'
      },
      {
        path: 'create',
        name: 'Create Vault',
        component: () => import('@/modules/vault/pages/create-vault-page.vue'),
        meta: {
          title: 'Create Vault',
          emptyLayout: true
        }
      },
      {
        path: 'connect-wallet',
        name: 'Connect Wallet',
        component: () => import('@/modules/vault/pages/connect-wallet-page.vue'),
        meta: {
          title: 'Connect Wallet'
        }
      },
      {
        path: '*',
        redirect: '/'
      }
    ]
  },
  {
    path: '/boolean-review',
    name: 'Boolean Review',
    component: () => import('@/modules/boolean-review/pages/boolean-review-page.vue'),
    meta: {
      title: 'Boolean Review'
    }
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
