import Vue from 'vue'
import { VTextField } from 'vuetify/lib'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 960
  },
  theme: {
    options: {
      customProperties: true
    },
    dark: true,
    themes: {
      dark: {
        primary: { base: '#00D079' },
        'green-1': '#00D079',
        'green-2': '#B9FFE2',
        'green-3': '#1F2B26',
        'green-4': '#BBCEC6',
        grey: '#A7A6A6',
        'dark-1': '#3E3E3E',
        'dark-2': '#2B2B2B',
        'dark-3': '#1D1D1D',
        'dark-4': '#141414',
        white: '#FFFFFF',
        black: '#000000',
        orange: '#F78A26'
      }
    }
  }
})

Vue.component('c-text-field', {
  extends: VTextField,
  props: {
    solo: {
      type: Boolean,
      default: true
    },
    flat: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: true
    },
    hideDetails: {
      type: Boolean,
      default: true
    },
    hideSpinButtons: {
      type: Boolean,
      default: true
    },
    backgroundColor: {
      type: String,
      default: 'black'
    }
  }
})
