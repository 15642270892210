


















import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'
import { appProvider } from './app-providers'
import { walletStore } from './stores/wallet-store'

@Observer
@Component
export default class App extends Vue {
  drawer = false

  get showNav() {
    return this.$route.meta!.emptyLayout
  }

  created() {
    walletStore.start()
    appProvider.router = this.$router
  }
}
