import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import { plugins } from './plugins/plugins'
import { filters } from './plugins/filters'
import { components } from './plugins/components'
import VueApexCharts from 'vue-apexcharts'

Vue.config.productionTip = false

Vue.component('apexchart', VueApexCharts)
Vue.use(VueApexCharts)

components()
plugins()
filters()

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
